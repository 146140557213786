import React, { useState, useEffect } from "react";
import axios from "axios";
import { dnsRecord } from "../types";

export default async function dnsLookup(dnsName: string) {
  try {
    const response = await axios.get("https://dns.google/resolve", {
      params: {
        name: dnsName,
        type: "A",
      },
    });

    // Extract the IP address from the response
    const answer = response.data.Answer;
    if (answer && answer.length > 0) {
      const ipAddress = answer.find(
        (record: dnsRecord) => record.type === 1
      )?.data;
      return ipAddress;
    } else {
      console.log(`No A record found for ${dnsName}`);
      return null;
    }
  } catch (error) {
    console.log("Failed to fetch IP address");
    return null;
  }
}
