import axios from "axios";
import { YTSData } from "../types";
import { videoQualities } from "../components/Filters";
import dnsLookup from "./dnsLookup";

const dnsName = "yts.mx";

// Initialize APICall dynamically based on IP address
async function createAPICall() {
  const ipAddress = await dnsLookup(dnsName);
  const defaultIp = "104.31.16.120";
  const ipBaseURL = `https://${ipAddress ?? defaultIp}/api/v2/`;

  return axios.create({
    baseURL: ipBaseURL,
    headers: {
      Host: dnsName, // Trick the server into thinking the request is made to yts.mx
    },
  });
}

export type ytsSearchParams = {
  query_term: string;
  limit?: number;
  page?: number;
  quality?: videoQualities;
  minimum_rating?: number;
  genre?:
    | "Action"
    | "Adventure"
    | "Animation"
    | "Biography"
    | "Comedy"
    | "Crime"
    | "Documentary"
    | "Drama"
    | "Family"
    | "Fantasy"
    | "Film Noir"
    | "History"
    | "Horror"
    | "Music"
    | "Musical"
    | "Mystery"
    | "Romance"
    | "Sci-Fi"
    | "Short Film"
    | "Sport"
    | "Superhero"
    | "Thriller"
    | "War"
    | "Western";
  sort_by?:
    | "title"
    | "year"
    | "rating"
    | "peers"
    | "seeds"
    | "download_count"
    | "like_count"
    | "date_added";
  order_by?: "desc" | "asc";
};

export const YTSClient = {
  search: async ({
    query_term,
    limit,
    page,
    quality,
    minimum_rating,
    genre,
    sort_by,
    order_by,
  }: ytsSearchParams): Promise<YTSData> => {
    const APICall = await createAPICall();
    const { data } = await APICall.get("list_movies.json", {
      params: {
        limit,
        page,
        quality,
        minimum_rating,
        query_term,
        genre,
        sort_by,
        order_by,
        with_rt_ratings: true,
      },
    });

    return data.data;
  },
};
